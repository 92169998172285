/*
 *  Copyright 2022 Red Hat
 *
 *  Licensed under the Apache License, Version 2.0 (the "License");
 *  you may not use this file except in compliance with the License.
 *  You may obtain a copy of the License at
 *
 *      https://www.apache.org/licenses/LICENSE-2.0
 *
 *  Unless required by applicable law or agreed to in writing, software
 *  distributed under the License is distributed on an "AS IS" BASIS,
 *  WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 *  See the License for the specific language governing permissions and
 *  limitations under the License.
 */
// Patternfly colors
// https://www.patternfly.org/wp-content/uploads/2013/08/color-palette.jpg

@hover-color: #d1d1d1;
@active-item-color: #bee1f4;

@hal-dark: #393f44;
@hal-dark-gray: #505459;
@hal-dark-blue: #00618a;
@hal-blue: #0099d3;
@hal-gray: lighten(@hal-dark-gray, 40%);
@hal-light-gray: #e7e7e7;
@hal-almost-white: #f9f9f9;

@hal-form-label-columns: 3;
@hal-form-input-columns: 9;
@hal-dialog-md-label-columns: 4;
@hal-dialog-md-input-columns: 8;

@finder-border-color: #b7babe;
@finder-padding-tb: 10px;
@finder-padding-lr: 15px;

@margin-large: 20px;
@margin-small: 10px;

:root {
  --hal-header-height: 71px;
  --hal-header-height-breadcrumb: 42px;
  --hal-header-height-sm: 38px; // for width < 768px
  --hal-footer-height: 26px;
  --stability-offset: 20px;
  --stability-experimental-color: #C9190B;
  --stability-preview-color: #F0AB00;
}
