/*
 *  Copyright 2022 Red Hat
 *
 *  Licensed under the Apache License, Version 2.0 (the "License");
 *  you may not use this file except in compliance with the License.
 *  You may obtain a copy of the License at
 *
 *      https://www.apache.org/licenses/LICENSE-2.0
 *
 *  Unless required by applicable law or agreed to in writing, software
 *  distributed under the License is distributed on an "AS IS" BASIS,
 *  WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 *  See the License for the specific language governing permissions and
 *  limitations under the License.
 */
h2.underline {
  border-bottom: 1px solid #ededed;
}

.bootstrap-error.layout-pf.layout-pf-fixed body {
  padding-top: 28px;
  padding-bottom: 0;
}

.break-tooltip + .tooltip > .tooltip-inner {
  word-wrap: break-word;
}

.btn-group > .dropdown:first-child {
  margin-left: 0;
}

.btn-group .dropdown + .dropdown,
.btn-group .dropdown + .btn,
.btn-group .dropdown + .btn-group,
.btn-group .btn + .dropdown,
.btn-group .btn-group + .dropdown {
  margin-left: -1px;
}

.flex-row {
  display: flex;
  flex-flow: row;
}

.layout-pf.layout-pf-fixed body {
  padding-top: calc(var(--hal-header-height) + var(--stability-offset));
  @media (max-width: @screen-sm) {
    padding-top: calc(var(--hal-header-height-sm) + var(--stability-offset));
  }
  padding-bottom: var(--hal-footer-height);
}

.link {
  color: #0088ce;
  text-decoration: none;
}

.margin-bottom-5 {
  margin-bottom: 5px;
}

.margin-bottom-large {
  margin-bottom: @margin-large;
}

.margin-bottom-small {
  margin-bottom: @margin-small;
}

.margin-left-small {
  margin-left: @margin-small;
}

.margin-left-5 {
  margin-left: 5px;
}

.margin-right-5 {
  margin-right: 5px;
}

.margin-right-large {
  margin-right: @margin-large;
}

.margin-right-small {
  margin-right: @margin-small;
}

.margin-top-5 {
  margin-top: 5px;
}

.margin-top-large {
  margin-top: @margin-large;
}

.margin-top-small {
  margin-top: @margin-small;
}

.page {
  margin-top: @margin-large;
}

.popover-title .close {
  color: #666666;
  font-size: small;
}

.rbac-hidden {
  display: none !important;
}

.small-link {
  font-size: 12px;
  font-weight: normal;
  line-height: 1;
}

.with-progress {
  animation: progress-animation 1s linear infinite;
  background-size: 40px 40px;
  background-image: linear-gradient(
          45deg,
          rgba(0, 0, 0, 0.1) 25%,
          transparent 25%,
          transparent 50%,
          rgba(0, 0, 0, 0.1) 50%,
          rgba(0, 0, 0, 0.1) 75%,
          transparent 75%,
          transparent
  );
}

.wrap {
  white-space: pre-wrap;
}

@keyframes progress-animation {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 80px 40px;
  }
}
