/*
 *  Copyright 2022 Red Hat
 *
 *  Licensed under the Apache License, Version 2.0 (the "License");
 *  you may not use this file except in compliance with the License.
 *  You may obtain a copy of the License at
 *
 *      https://www.apache.org/licenses/LICENSE-2.0
 *
 *  Unless required by applicable law or agreed to in writing, software
 *  distributed under the License is distributed on an "AS IS" BASIS,
 *  WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 *  See the License for the specific language governing permissions and
 *  limitations under the License.
 */
.navbar-brand {
  &.logo {
    padding: 6px 0;
    outline: none;
    &:hover, &:focus {
      color: #f1f1f1;
    }
    @media (min-width: 768px) {
      padding-top: 3px;
      padding-bottom: 2px;
    }
  }
}

.logo-text {
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 300;
  font-size: 14px;
  &.logo-text-first {
    font-weight: 700;
  }
  &.logo-text-last {
    font-weight: 300;
  }
}

.dropdown-menu > li.static {
  display: block;
  clear: both;
  font-weight: 400;
  line-height: 1.66666667;
  color: #333;
  white-space: nowrap;
  border-color: transparent;
  border-style: solid;
  border-width: 1px 0;
  padding: 1px 10px;
  @media (max-width: 767px) {
    background-color: transparent;
    border: 0;
    color: #cfcfcf;
    outline: 0;
    line-height: 20px;
    padding: 5px 15px 5px 30px;
  }
}

.user-dropdown {
  max-width: 260px;
}

.active-roles {
  .text-overflow();
}

.navbar-pf {
  top: var(--stability-offset) !important;
}

.navbar-pf .drawer-pf-hal {
  height: calc(100vh - 54px);
}

.navbar-pf .navbar-utility li.dropdown > .dropdown-toggle .fa {
  left: 10px;
  top: 7px;
  position: absolute;
  @media (max-width: 767px) {
    left: 20px;
    position: absolute;
    top: 10px;
  }
}

.navbar-utility > li > a > .fa, .navbar-utility > li > a > .pficon {
  padding-right: 4px;
}

.navbar-pf .navbar-primary > li > a {
  outline: none;
}

.navbar-nav .dropdown.open .dropdown-menu.messages {
  margin-top: 0;
}

.navbar-pf .navbar-nav .badge {
  min-width: 7px;
  min-height: 7px;
  right: 8px;
  top: 5px;
}

.messages {
  .list-group-item {
    cursor: pointer;

    &.visited {
      opacity: .66;
    }

    .message:hover {
      text-decoration: underline;
    }
  }
}

.hal-breadcrumb {
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: 0;
  padding-top: 10px;
  padding-bottom: 9px;
  // TODO If we enable this dropdown menus will be clipped
  //overflow-y: auto;

  @media (max-width: 767px) {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  #gradient > .vertical(@navbar-pf-navbar-primary-bg-color-start, @navbar-pf-navbar-primary-bg-color-stop);

  > li {
    color: #fff;
    display: flex;
    flex-wrap: nowrap;
    font-size: 14px;

    @media (max-width: 767px) {
      font-size: 12px;
    }

    > .arrow {
      color: #999;
      font-size: 14px;
      padding: 0 5px;
      @media (max-width: 767px) {
        font-size: 12px;
      }
    }

    > a, .key > a, .value > a, .value.dropdown > a {
      color: #fff;

      &.back {
        padding-left: 20px;
      }
    }

    .value > .value-dropdown {
      max-height: 400px;
      overflow-x: scroll;
      top: 26px;

      li.empty {
        color: #999;
        font-style: italic;
        padding: 5px;
      }
    }

    .back, .key, .value {
      white-space: nowrap;
    }
  }

  > li + li:before {
    content: '/';
    font-size: 14px;
    @media (max-width: 767px) {
      font-size: 12px;
    }
    padding: 0 10px;
  }
  > li + li.breadcrumb-tools:before {
    content: '';
  }

  li.active {
    color: #7dc3e8;
    .arrow {
      color: #bee1f4;
    }
    > a, .key > a, .value > a, .value.dropdown > a {
      color: #7dc3e8;
    }
  }

  li.breadcrumb-tools {
    align-items: center;
    flex-grow: 1;
    justify-content: flex-end;
    padding-right: 20px;

    a {
      margin-left: @margin-small;
    }
  }
}